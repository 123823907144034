import React, { useCallback, useEffect, useState, useContext } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';


import ClickToCopy from '../../widgets/copy_onclick';
import GenerateURLForm from './forms/generate_url';
import { ApiHelper } from '../../../helpers/apihelper';
import * as Constants from './../../../helpers/constants';
import { filterProductsByBilling } from './../helpers/helper';
import AppConfigContext from '../../app_layout/app_config_context';
import * as GeneratePurchaseUrlConstants from './../helpers/constant';
import { clientAppHelper } from './../../../helpers/clientapphelper/clearout_client_app_helper';

import './../../../static/css/products/create_product.css';
import './../../../static/css/products/product_cards.css';

const initialStates = {
  billing_type: Constants.BILLING_TYPE.one_time.value,
  currency_code: Constants.CURRENCY.USD.name,
  product_id: '',
  quantity: 0,
  coupon_code: '',
  selected_product: null,
  productOptions: [],
  couponOptions: [],
};

const GeneratePurchaseURL = () => {
  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client


  const BASE_URL = clientAppHelper(client).PRICING_URL;


  const location = useLocation();

  const [state, setState] = useState(() => ({
    ...initialStates,
    generatedURL: `${BASE_URL}?showbuynow=true`,
  }));


  useEffect(() => {
    // Reset state when URL changes
    setState({
      ...initialStates,
      generatedURL: `${BASE_URL}?showbuynow=true`,
    });
  }, [location.pathname, BASE_URL]);


  const fetchProducts = useCallback((currency, billingType) => {
    setState((prevState) => ({
      ...prevState,
      productOptions: [],
    }));

    const requestQueryParams = {
      limit: 100,
      skip: 0,
      total_required: true,
      filters: JSON.stringify({
        status: 'active',
        currency,
      }),
      client_secret: Constants.CLIENT_SECRET,
    };

    const queryParams = _.pick(requestQueryParams, ApiHelper(client, 'GET_PRODUCT_DROP_DOWN_LIST').API_QUERY);

    ApiHelper(client, 'GET_PRODUCT_DROP_DOWN_LIST')
      .API(client, queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          const newList = response.data.data?.lists || response.data.data || [];
          let filteredProducts = filterProductsByBilling(newList, billingType);

          // Exclude products based on the constant array
          filteredProducts = filteredProducts.filter((prod) => !GeneratePurchaseUrlConstants.EXCLUDED_PRODUCT_IDS.includes(prod._id));

          const prodList = filteredProducts.map((prod) => ({
            label: (
              <div className="d-flex justify-content-between">
                <span>{prod.display_name}</span>
                {billingType === Constants.BILLING_TYPE.one_time.value ? (
                  <span> {billingType}</span>
                ) : (
                  <span>
                    {Constants.CURRENCY[prod.price.currency].symbol} {prod.price.total_price} ({billingType})
                  </span>
                )}
              </div>
            ),
            value: prod._id,
            product: prod, 
          }));

          const defaultProduct = prodList.length > 0 ? prodList[0] : null;

          setState((prevState) => ({
            ...prevState,
            productOptions: prodList,
            selected_product: defaultProduct ? defaultProduct.product : null,
            product_id: defaultProduct ? defaultProduct.value : ''
          }));
        }
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, [client]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selected_product: null,
      product_id: '', // Reset product selection
      coupon_code: '', // Reset coupon selection
      quantity: 0, // Reset quantity
    }));
  }, [state.currency_code, state.billing_type]);

  useEffect(() => {
    fetchProducts(state.currency_code, state.billing_type);
  }, [fetchProducts, state.currency_code, state.billing_type]);

  useEffect(() => {

    const product_id = state.product_id
    const quantity = state.quantity
    const coupon_code = state.coupon_code
    const currency_code = state.currency_code


    const queryParams = new URLSearchParams({
      showbuynow: 'true',
    });

    if (product_id) {
      queryParams.append('product_id', product_id);
    }

    if (quantity > 0) {
      queryParams.append('quantity', quantity);
    }

    if (coupon_code) {
      queryParams.append('coupon_code', coupon_code);
    }

    if (currency_code && currency_code.toLowerCase() !== Constants.CURRENCY.USD.value) {
      queryParams.append('currency', Constants.CURRENCY.INR.value);
    }

    setState((prevState) => ({
      ...prevState,
      generatedURL: `${BASE_URL}?${queryParams.toString()}`,
    }));
  }, [state.product_id, state.currency_code, state.quantity, state.coupon_code, BASE_URL]);

  const onStateChange = useCallback((stateKey, stateValue) => {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: stateValue !== undefined ? stateValue : prevState[stateKey],
    }));
  }, []);

  return (
    <>
      <div id="page-header">
        <h1 className="m-0 header">Generate Buy Now URL</h1>
      </div>
      <div id="page-content">
        <div className="create-product">
          <GenerateURLForm
            onStateChange={onStateChange}
            selected_product={state.selected_product}
            productOptions={state.productOptions}
            product_id={state.product_id}
            coupon_code={state.coupon_code}
            billing_type={state.billing_type}
            {...state}
          />
          <div className="product-view product-view-container">
            <div className="generate-url">
              <ClickToCopy text={state.generatedURL} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneratePurchaseURL;

import React from 'react'
import { isMobile } from 'react-device-detect'

import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'

const UserSubscriptionsTable = ({ data = [], columns = [] }) => {

  const tableData = {
    nodes: data,
  }

  const tableTheme = useTheme(
    [
      getTheme(),
      {
        Table: `
        --data-table-library_grid-template-columns: 
          minmax(18rem, 1fr) // displayName
          minmax(22rem, 1fr) // Subscription Status
          minmax(20rem, 1fr) // Price
          minmax(21rem, 1fr); // Billing

          grid-template-rows: max-content

          height: 100%;
        `,
        HeaderCell: `
        height: fit-content;
      `,
        Row: `
          font-size:14px;
        `
      }
    ]
  )

  return (
    <div className='subscription-history-table'>
      <CompactTable
        columns={columns}
        data={tableData}
        layout={{
          fixedHeader: true,
          horizontalScroll: !isMobile,
          custom: true
        }}
        theme={tableTheme}
      />
    </div>
  )
}

export default UserSubscriptionsTable
import React, { useState, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as Constants from '../../helpers/constants'
import * as Helpers from '../../helpers/helper'
import showToastNotification from '../widgets/toastify';
import CustomOverlayTrigger from '../widgets/overlay_trigger'
import { ApiHelper } from '../../helpers/apihelper'

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';


const initialState = {
  couponName: '',
  description: '',
  couponId: '',
  affiliate: '',
  couponType: 'discount',
  discountType: 'percentage',
  allowRedeemCount: Constants.COUPON_REDEEM_COUNT,
  showModal: false,
  errors: {},
  isApiCallInProgress: false,
  couponStatus: 'active',
  userId: '',
  discountMin: Constants.COUPON_MIN_FOR_FIXED,
  discountMax: Constants.COUPON_MAX_VALUE_CREDITS,
  discountVal: Constants.COUPON_DISCOUNT_FIXED_DEFAULT,
  credits: Constants.COUPON_MIN_VALUE_CREDITS,
  dailyVerifyLimit: 0,
  expiryDate: moment.utc().add(7, 'days').toDate(),
}

function CreateCoupon({ client, getClearoutUsers, dropdowns }) {
  const [state, setState] = useState(initialState)
  const [selectedOption, setSelectedOption] = useState(null);
  const abortControllerRef = useRef(null);


  const validateForm = () => {
    let err = {}
    if (state.couponName === '') {
      err['coupon_name'] = 'Please enter coupon name'
    }
    if (state.couponId === '') {
      err['coupon_id'] = 'Please enter coupon id'
    }
    if (state.couponName === null || state.couponName.length < 1) {
      err['coupon_name'] = 'Please enter coupon name'
    }
    if (state.couponId.trim() === null || state.couponId.length < 1) {
      err['coupon_id'] = 'Please enter coupon id'
    }
    if (state.couponId.trim() && state.couponId.length <= Constants.COUPON_MIN_LENGTH) {
      err['coupon_id'] = 'Coupon id min length should be ' + Constants.COUPON_MIN_LENGTH
    }
    if (state.isEdit && state.couponId.trim() && state.couponId.length > Constants.COUPON_MAX_LENGTH) {
      err['coupon_id'] = 'Coupon Id max length is ' + Constants.COUPON_MAX_LENGTH
    }

    let couponIdValid = Helpers.isAlphaNumeric(state.couponId.trim())
    if (state.couponId.trim() !== null && !couponIdValid) {
      err['coupon_id'] =
        'Please enter only alphanumeric letters for coupon id'
    }

    if (state.expiryDate === null || state.expiryDate.length < 1) {
      err['expiry_date'] = 'Please enter expiry date'
    }
    //coupon type== standard
    if (state.couponType === 'standard' && state.credits < 1) {
      err['credits'] = 'Please enter credits'
    }
    //coupon type== discount
    if (
      state.couponType === 'discount' &&
      Number(state.discountMin) < 1
    ) {
      err['discount_min'] = 'Please enter discount min value'
    }

    if (
      state.couponType === 'discount' &&
      Number(state.discountMax) < 1
    ) {
      err['discount_max'] = 'Please enter discount max value'
    }

    if (
      state.couponType === 'discount' &&
      Number(state.discountVal) < 0
    ) {
      err['discount_val'] = 'Please enter discount value'
    }

    if (
      state.couponType === 'discount' &&
      (state.discountType === 'fixed' ||
        state.discountType === 'percentage') &&
      Number(state.discountMin) < Constants.COUPON_MIN_FOR_FIXED
    ) {
      err['discount_min'] =
        'Min value should be more than $' + Constants.COUPON_MIN_FOR_FIXED
    }

    if (
      state.couponType === 'discount' &&
      state.discountType === 'slab' &&
      Number(state.discountMin) < Constants.COUPON_SLAB_MAX_UNIT_PRICE
    ) {
      err['discount_min'] =
        'Min value should be more than ' + Constants.COUPON_MIN_VALUE_CREDITS
    }

    if (
      state.couponType === 'discount' &&
      state.discountType === 'slab' &&
      Number(state.discountVal) > Constants.COUPON_SLAB_MAX_UNIT_PRICE
    ) {
      err['discount_val'] =
        'Unit price for slab should be less than ' +
        Constants.COUPON_SLAB_MAX_UNIT_PRICE
    }

    if (
      state.couponType === 'discount' &&
      Number(state.discountMin) > Number(state.discountMax)
    ) {
      err['discount_min'] = 'Max should be greater than min'
    }

    if (state.couponType === 'bonus') {
      if (state.discountType === 'duration') {
        if (Number(state.discountMin) < 1) {
          err['discount_min'] = 'Please enter discount min value'
        }

        if (Number(state.discountMin) < 3000) {
          err['discount_min'] =
            'Min credit should be more than or equal to 3000'
        }

        if (Number(state.discountMin) > 5000000) {
          err['discount_min'] =
            'Min credit should be less than or equal to 5000000'
        }

        if (Number(state.discountMax) < 1) {
          err['discount_max'] = 'Please enter discount max value'
        }

        if (Number(state.discountMax) < 3000) {
          err['discount_max'] =
            'Max credit should be more than or equal to 3000'
        }

        if (Number(state.discountMax) > 5000000) {
          err['discount_max'] =
            'Max credit should be less than or equal to 5000000'
        }

        if (Number(state.discountMax) < Number(state.discountMin)) {
          err['discount_max'] =
            'Max credit should be more than min credits'
        }
        if (!state.discountVal) {
          err['discount_val'] = 'Please enter discount value'
        }

        if (Number(state.discountVal) < 0) {
          err['discount_val'] = 'Please enter discount value'
        }
      }
    }


    if (Object.keys(err).length) {
      setState((prevState) => ({ ...prevState, errors: err }))
      setTimeout(() => {
        setState((prevState) => ({ ...prevState, errors: {} }))
      }, 5000);
      return false
    }
    return true
  }

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    if (validateForm()) {
      let requestBody = {
        name: state.couponName,
        coupon: state.couponId.toUpperCase(),
        expiry: moment(state.expiryDate).toISOString(),
        status: state.couponStatus,
        user_id: state.userId,
        description: state.description,
        affiliated_to: state.affiliate,
        coupon_type: state.couponType,
        allowed_redeem_count: state.allowRedeemCount,
        client_secret: Constants.CLIENT_SECRET,
      };

      if (state.couponType === 'discount' || state.couponType === 'bonus') {
        requestBody.discount_type = state.discountType;
        requestBody.discount_min = state.discountMin;
        requestBody.discount_max = state.discountMax;
        requestBody.discount_value = state.discountVal;
      } else {
        requestBody.credits = state.credits;
        requestBody.daily_verify_limit = state.dailyVerifyLimit === 0 ? null : state.dailyVerifyLimit;
      }

      let body = _.pick(requestBody, ApiHelper(client, 'CREATE_COUPONS').API_BODY)

      setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
      ApiHelper(client, 'CREATE_COUPONS').API(client, body)
        .then((response) => {
          if (response.data.status === Constants.CO_API.FAILED) {
            showToastNotification({
              message: response.data.message,
              isSuccess: false,
            });
          } else if (response.status === Constants.CO_API.SUCCESS) {
            showToastNotification({
              message: response.data.message,
              isSuccess: true,
            });
            getClearoutUsers(0, 50, dropdowns, {})
            setState(initialState)

            // @CHECK STATE NOT BEING USED ANYWHERE
            setState(prevState => ({ ...prevState, isButtonDisabled: false }))
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
          handleCloseModal();
        });
    }
  };

  const getUsersList = (value) => {
    if (!value) {
      return [];
    }

    // Abort previous request if exists
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;

    let requestBody = {
      search: value,
      filters: {
        status: 'active',
        user_role: 'owner'
      },
      client_secret: Constants.CLIENT_SECRET,
    };

    let body = _.pick(requestBody, ApiHelper(client, 'USER_LIST').API_BODY)
    return ApiHelper(client, 'USER_LIST').API(client, body, controller.signal)
      .then((res)=>{
        if (res && res.data) {
          let searchResults = res.data.data;
          return searchResults.map(user => ({
            label: user.email,
            value: user._id
          }));
        }
        return [];
      }).catch((error)=>{
        console.log(error)
        return [];
      })
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => {
    setState(initialState)
    setSelectedOption(null)
  }


  const handleCouponNameChange = (e) => {
    setState((prevState) => ({ ...prevState, couponName: e.target.value }))
  }

  const handleCouponIdChange = (e) => {
    setState((prevState) => ({ ...prevState, couponId: e.target.value }))
  }

  const handleMinCreditsChange = (e) => {
    setState((prevState) => ({ ...prevState, discountMin: e.target.value }))
  }
  const handleMaxCreditsChange = (e) => {
    setState((prevState) => ({ ...prevState, discountMax: e.target.value }))
  }

  const handleUnitPriceChange = (e) => {
    setState((prevState) => ({ ...prevState, discountVal: e.target.value }))
  }

  const handleFixedMinCreditsChange = (e) => {
    setState((prevState) => ({ ...prevState, discountMin: e.target.value }))
  }
  const handleFixedMaxCreditsChange = (e) => {
    setState((prevState) => ({ ...prevState, discountMax: e.target.value }))
  }

  const handleFixedUnitPriceChange = (e) => {
    setState((prevState) => ({ ...prevState, discountVal: e.target.value }))
  }

  const handleAffiliate = (e) => {
    setState((prevState) => ({ ...prevState, affiliate: e.target.value }))
  }

  const handleStatusChange = (eventKey) => {
    if (eventKey === 'active') {
      setState((prevState) => ({ ...prevState, couponStatus: 'active' }))
    } else if (eventKey === 'inactive') {
      setState((prevState) => ({ ...prevState, couponStatus: 'inactive' }))
    }
  }

  const handleRedeemChange = (e) => {
    setState((prevState) => ({ ...prevState, allowRedeemCount: e.target.value }))
  }

  const handleCouponTypeChange = (eventKey) => {
    if (eventKey === 'discount') {
      setState((prevState) => ({
        ...prevState, couponType: 'discount',
        discountType: 'percentage', discountMin: Constants.COUPON_MIN_FOR_FIXED, discountMax: Constants.COUPON_MAX_VALUE_CREDITS,
        discountVal: Constants.COUPON_DISCOUNT_FIXED_DEFAULT
      }))
    } else if (eventKey === 'standard') {
      setState((prevState) => ({ ...prevState, couponType: 'standard' }))
    } else {
      setState((prevState) => ({
        ...prevState, couponType: 'bonus', discountType: 'duration',
        discountMin: Constants.MIN_VAL, discountMax: Constants.MAX_VAL, discountVal: Constants.DURATION_VALUE
      }))
    }
  }

  const handleDiscountType = (eventKey) => {
    if (eventKey === 'slab') {
      setState((prevState) => ({ ...prevState, discountType: 'slab', discountMin: Constants.COUPON_MIN_VALUE_CREDITS, discountMax: Constants.COUPON_CREDITS_DEFAULT_VALUE, discountVal: Constants.COUPON_SLAB_UNIT_PRICE }))
    } else if (eventKey === 'fixed') {
      setState((prevState) => ({ ...prevState, discountType: 'fixed', discountMin: Constants.COUPON_MIN_FOR_FIXED, discountMax: Constants.COUPON_MAX_VALUE_CREDITS, discountVal: Constants.COUPON_DISCOUNT_FIXED_DEFAULT }))
    } else if (eventKey === 'duration') {
      setState((prevState) => ({ ...prevState, discountType: 'duration' }))
    } else {
      setState((prevState) => ({
        ...prevState, discountType: 'percentage', discountMin: Constants.COUPON_MIN_FOR_FIXED, discountMax: Constants.COUPON_MAX_VALUE_CREDITS,
        discountVal: Constants.COUPON_DISCOUNT_FIXED_DEFAULT
      }))
    }
  }

  const handleDescriptionChange = (e) => {
    setState((prevState) => ({ ...prevState, description: e.target.value }))
  }

  const handleApplicableForChange = (option) => {
    setSelectedOption(option);
    setState((prevState) => ({ ...prevState, userId: option ? option.value : '' }));
    // console.log(state.userId)
  };

  const handleMinAmt = (e) => {
    setState((prevState) => ({ ...prevState, discountMin: e.target.value }))
  }

  const handleMinCredits = (e) => {
    setState((prevState) => ({ ...prevState, discountMin: e.target.value }))
  }

  const handleMaxCredits = (e) => {
    setState((prevState) => ({ ...prevState, discountMax: e.target.value }))
  }

  const handleDurationInMonth = (e) => {
    setState((prevState) => ({ ...prevState, discountVal: e.target.value }))
  }

  const handleMaxAmt = (e) => {
    setState((prevState) => ({ ...prevState, discountMax: e.target.value }))
  }

  const handleDiscountPercentage = (e) => {
    setState((prevState) => ({ ...prevState, discountVal: e.target.value }))
  }

  const handleStandardCreditsChange = (e) => {
    setState((prevState) => ({ ...prevState, credits: e.target.value }))
  }

  const handleStandardDailyVerifyLimitChange = (e) => {
    setState((prevState) => ({ ...prevState, dailyVerifyLimit: e.target.value }))
  }

  return (
    <>
      <Button
        onClick={handleShowModal}
        size='sm'
        variant='outline-primary'
      >
        Create Coupon
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
        size='md'
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Create Coupon</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Name:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <div className='d-flex'>
                    <Form.Control
                      value={state.couponName}
                      onChange={handleCouponNameChange}
                    />
                    <span className='pt-2 ps-2'>
                      <CustomOverlayTrigger
                        content={Constants.COUPON_CONSTANTS.NAME}
                        placement='top'
                      >
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                      </CustomOverlayTrigger>
                    </span>
                  </div>
                  {state.errors ? <p className='text-danger'>{state.errors.coupon_name}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Coupon Id:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <div className='d-flex'>
                    <Form.Control
                      // type='text'
                      value={state.couponId}
                      onChange={handleCouponIdChange}
                      maxLength={50}
                    />
                    <span className='pt-2 ps-2'>
                      <CustomOverlayTrigger
                        content={Constants.COUPON_CONSTANTS.COUPON_ID}
                        placement='top'
                      >
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                      </CustomOverlayTrigger>
                    </span>
                  </div>
                  {state.errors ? <p className='text-danger'>{state.errors.coupon_id}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Affiliated to:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <div className='d-flex'>
                    <Form.Control
                      // type='text'
                      value={state.affiliate}
                      onChange={handleAffiliate}
                      maxLength={50}
                    />
                    <span className='pt-2 ps-2'>
                      <CustomOverlayTrigger
                        content={Constants.COUPON_CONSTANTS.AFFILIATE}
                        placement='top'
                      >
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                      </CustomOverlayTrigger>
                    </span>
                  </div>
                </div>
              </Form.Group>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Status:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Dropdown onSelect={handleStatusChange}>
                    <Dropdown.Toggle
                      variant='secondary'
                      id='coupon-dropdown'
                    >
                      {Helpers.capitalizeFirstLetter(state.couponStatus)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='coupon-dropdown'>
                      <Dropdown.Item eventKey='active'>Active</Dropdown.Item>
                      <Dropdown.Item eventKey='inactive'>Inactive</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Redeem Count:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <div className='d-flex'>
                    <Form.Control
                      type='number'
                      value={state.allowRedeemCount}
                      onChange={handleRedeemChange}
                    />
                    <span className='pt-2 ps-2'>
                      <CustomOverlayTrigger
                        content={Constants.COUPON_CONSTANTS.REDEEM_COUNT}
                        placement='top'
                      >
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                      </CustomOverlayTrigger>
                    </span>
                  </div>
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex gap-4'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100'>Coupon type:</Form.Label>
                  <div className='d-flex'>
                    <Dropdown onSelect={handleCouponTypeChange}>
                      <Dropdown.Toggle
                        variant='secondary'
                        id='coupon-dropdown'
                      >
                        {Helpers.capitalizeFirstLetter(state.couponType)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='coupon-dropdown'>
                        <Dropdown.Item eventKey='discount'>{Constants.COUPONS_DROPDOWNS[client.toUpperCase()].discount}</Dropdown.Item>
                        <Dropdown.Item eventKey='standard'>{Constants.COUPONS_DROPDOWNS[client.toUpperCase()].standard}</Dropdown.Item>
                        <Dropdown.Item eventKey='bonus'>{Constants.COUPONS_DROPDOWNS[client.toUpperCase()].bonus}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <span className='pt-2 px-1'>
                      <CustomOverlayTrigger
                        content={Constants.COUPON_CONSTANTS.COUPON_TYPE}
                        placement='top'
                      >
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                      </CustomOverlayTrigger>
                    </span>
                  </div>
                </div>
                {state.couponType === 'discount' ? (
                  <div className='col-md-9'>
                    <Form.Label className='w-100'>Discount type:</Form.Label>
                    <div className='d-flex'>
                      <Dropdown onSelect={handleDiscountType}>
                        <Dropdown.Toggle
                          variant='secondary'
                          id='coupon-dropdown'
                        >
                          {state.discountType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='coupon-dropdown'>
                          <Dropdown.Item eventKey='slab'>Slab</Dropdown.Item>
                          <Dropdown.Item eventKey='fixed'>Fixed</Dropdown.Item>
                          <Dropdown.Item eventKey='percentage'>Percentage</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className='pt-2 px-1'>
                        <CustomOverlayTrigger
                          content={Constants.COUPON_CONSTANTS.DISCOUNT_TYPE}
                          placement='top'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        </CustomOverlayTrigger>
                      </span>
                    </div>
                  </div>
                ) : null}
                {state.couponType === 'standard' ? (
                  <div className='row'>
                    <Form.Group
                      className='d-flex'
                    >
                      <div className='col-md-5 pb-3'>
                        <Form.Label>Credits:</Form.Label>
                        <div className='d-flex'>
                          <Form.Control
                            // type='text'
                            value={state.credits}
                            onChange={handleStandardCreditsChange}
                            maxLength={50}
                            className='w-75'
                          />
                          <span className='pt-2 px-1'>
                            <CustomOverlayTrigger
                              content={Constants.COUPON_CONSTANTS.CREDITS}
                              placement='top'
                            >
                              <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                            </CustomOverlayTrigger>
                          </span>
                        </div>
                        {state.errors ? <p className='text-danger'>{state.errors.credits}</p> : null}
                      </div>
                      <div className='col-md-5 d-flex justify-content-between pb-3'>
                        <div>
                          <Form.Label className='w-100'>Daily verify limit:</Form.Label>
                          <div className='d-flex'>
                            <Form.Control
                              // type='text'
                              value={state.dailyVerifyLimit}
                              onChange={handleStandardDailyVerifyLimitChange}
                              maxLength={50}
                              className='w-75'
                            />
                            <span className='pt-2 px-1'>
                              <CustomOverlayTrigger
                                content={Constants.COUPON_CONSTANTS.DAILY_VERIFY_LIMIT}
                                placement='top'
                              >
                                <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                              </CustomOverlayTrigger>
                            </span>
                          </div>
                        </div>
                      </div>
                    </Form.Group>
                  </div>
                ) : null}
                {state.couponType === 'bonus' ? (
                  <div className='col-md-9'>
                    <Form.Label className='w-100'>Discount type:</Form.Label>
                    <div className='d-flex'>
                      <Dropdown onSelect={handleDiscountType}>
                        <Dropdown.Toggle
                          variant='secondary'
                          id='coupon-dropdown'
                        >
                          {state.discountType}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='coupon-dropdown'>
                          <Dropdown.Item eventKey='duration'>Duration</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <span className='pt-2 px-1'>
                        <CustomOverlayTrigger
                          content={Constants.COUPON_CONSTANTS.DISCOUNT_TYPE}
                          placement='top'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        </CustomOverlayTrigger>
                      </span>
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </div>
            {state.discountType === 'percentage' && state.couponType === 'discount' ? (
              <div className='row'>
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-4 py-2'>
                    <Form.Label>Minimum Amt:</Form.Label>
                    <div className='d-flex'>
                      <Form.Control
                        // type='text'
                        value={state.discountMin}
                        onChange={handleMinAmt}
                        maxLength={50}
                        className='w-75'
                      />
                      <span className='pt-2 px-1'>
                        <CustomOverlayTrigger
                          content={Constants.COUPON_CONSTANTS.FIXED_MIN}
                          placement='top'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        </CustomOverlayTrigger>
                      </span>
                    </div>
                    {state.errors ? <p className='text-danger'>{state.errors.discount_min}</p> : null}
                  </div>
                  <div className='col-md-9 d-flex justify-content-around py-2'>
                    <div>
                      <Form.Label className='w-100'>Maximum Amt:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountMax}
                          onChange={handleMaxAmt}
                          maxLength={50}
                          className='w-75'
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.FIXED_MAX}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                      {state.errors ? <p className='text-danger'>{state.errors.discount_max}</p> : null}
                    </div>
                    <div>
                      <Form.Label className='w-100'>Discount Percentage:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountVal}
                          onChange={handleDiscountPercentage}
                          maxLength={50}
                          className='w-75'
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.PERC_VAL}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                      {state.errors ? <p className='text-danger'>{state.errors.discount_val}</p> : null}
                    </div>
                  </div>
                </Form.Group>
              </div>
            ) : null}
            {state.discountType === 'slab' && state.couponType === 'discount' ? (
              <div className='row'>
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-4 py-2'>
                    <Form.Label>Minimum Credits:</Form.Label>
                    <div className='d-flex'>
                      <Form.Control
                        // type='text'
                        value={state.discountMin}
                        onChange={handleMinCreditsChange}
                        maxLength={50}
                      />
                      <span className='pt-2 px-1'>
                        <CustomOverlayTrigger
                          content={Constants.COUPON_CONSTANTS.SLAB_MIN}
                          placement='top'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        </CustomOverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className='col-md-8 d-flex justify-content-between py-2'>
                    <div>
                      <Form.Label className='w-100'>Maximum Credits:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountMax}
                          onChange={handleMaxCreditsChange}
                          maxLength={50}
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.SLAB_MAX}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Form.Label className='w-100'>Unit Price:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountVal}
                          onChange={handleUnitPriceChange}
                          maxLength={50}
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.SLAB_VAL}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
            ) : null}
            {state.discountType === 'fixed' && state.couponType === 'discount' ? (
              <div className='row'>
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-4 py-2'>
                    <Form.Label>Minimum Amt:</Form.Label>
                    <div className='d-flex'>
                      <Form.Control
                        // type='text'
                        value={state.discountMin}
                        onChange={handleFixedMinCreditsChange}
                        maxLength={50}
                      />
                      <span className='pt-2 px-1'>
                        <CustomOverlayTrigger
                          content={Constants.COUPON_CONSTANTS.FIXED_MIN}
                          placement='top'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        </CustomOverlayTrigger>
                      </span>
                    </div>
                  </div>
                  <div className='col-md-8 d-flex justify-content-between py-2'>
                    <div>
                      <Form.Label className='w-100'>Maximum Amt:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountMax}
                          onChange={handleFixedMaxCreditsChange}
                          maxLength={50}
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.FIXED_MIN}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                    </div>
                    <div>
                      <Form.Label className='w-100'>Discount Amt:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountVal}
                          onChange={handleFixedUnitPriceChange}
                          maxLength={50}
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.FIXED_MIN}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </div>
            ) : null}
            {state.discountType === 'duration' && state.couponType === 'bonus' ? (
              <div className='row'>
                <Form.Group
                  className='d-flex'
                >
                  <div className='col-md-4 py-2'>
                    <Form.Label>Minimum Credits:</Form.Label>
                    <div className='d-flex'>
                      <Form.Control
                        // type='text'
                        value={state.discountMin}
                        onChange={handleMinCredits}
                        maxLength={50}
                        className='w-75'
                      />
                      <span className='pt-2 px-1'>
                        <CustomOverlayTrigger
                          content={Constants.COUPON_CONSTANTS.SLAB_MIN}
                          placement='top'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                        </CustomOverlayTrigger>
                      </span>
                    </div>
                    {state.errors ? <p className='text-danger'>{state.errors.discount_min}</p> : null}
                  </div>
                  <div className='col-md-9 d-flex justify-content-between py-2'>
                    <div>
                      <Form.Label className='w-100'>Maximum Credits:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountMax}
                          onChange={handleMaxCredits}
                          maxLength={50}
                          className='w-75'
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.SLAB_MAX}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                      {state.errors ? <p className='text-danger'>{state.errors.discount_max}</p> : null}
                    </div>
                    <div>
                      <Form.Label className='w-100'>Duration In Month:</Form.Label>
                      <div className='d-flex'>
                        <Form.Control
                          // type='text'
                          value={state.discountVal}
                          onChange={handleDurationInMonth}
                          maxLength={50}
                          className='w-75'
                        />
                        <span className='pt-2 px-1'>
                          <CustomOverlayTrigger
                            content={Constants.COUPON_CONSTANTS.DURATION_IN_MONTH}
                            placement='top'
                          >
                            <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                          </CustomOverlayTrigger>
                        </span>
                      </div>
                      {state.errors ? <p className='text-danger'>{state.errors.discount_val}</p> : null}
                    </div>
                  </div>
                </Form.Group>
              </div>
            ) : null}
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Expiry:</Form.Label>
                </div>
                <div className='col-md-9 pt-2'>
                  <DatePicker
                    selected={state.expiryDate}
                    onChange={(date) => setState((prevState) => ({ ...prevState, expiryDate: date }))}
                    timeInputLabel='Time:'
                    dateFormat='dd/MM/yyyy HH:mm'
                    showTimeInput
                  />
                  <small className='px-2 fw-bold'>In UTC</small>
                  {state.errors ? <p className='text-danger'>{state.errors.expiry_date}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Description:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    // type='number'
                    value={state.description}
                    onChange={handleDescriptionChange}
                  />
                  {state.errors ? <p className='text-danger'>{state.errors.credits}</p> : null}
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Applicable for:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <div className='d-flex'>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={getUsersList}
                      defaultOptions
                      onChange={handleApplicableForChange}
                      value={selectedOption}
                      isClearable
                      placeholder='Type to search....'
                      className='w-100'
                    />
                    <span className='pt-2 ps-2'>
                      <CustomOverlayTrigger
                        content={Constants.COUPON_CONSTANTS.USER_EMAIL}
                        placement='top'
                      >
                        <FontAwesomeIcon icon='fa-solid fa-circle-info' />
                      </CustomOverlayTrigger>
                    </span>
                  </div>
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='success'
            onClick={handleSaveChanges}
          // disabled={!state.credits || !state.description || state.isApiCallInProgress}
          >
            Create Coupon
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateCoupon;

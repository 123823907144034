import ApiService from '../apiservice'

export default {
  USER_LIST: {
    API: ApiService.getClearoutPhoneUsersList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  USER_ACTIVITY: {
    API: ApiService.getCopUsersActivities,
    API_BODY: ['co_user_id', 'skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  CREATE_USER: {
    API: ApiService.createClearoutPhoneUser,
    API_BODY: ['email', 'phone', 'client_secret']
  },
  USER_INFO: {
    API: ApiService.getCopUserInfo,
    API_BODY: ['client_secret', 'user_id']
  },
  EDIT_EMAIL: {
    API: ApiService.clearoutPhoneUpdateUserEmail,
    API_BODY: ['cop_user_id', 'user_id', 'email', 'client_secret']
  },
  UPDATE_TIMEZONE: {
    API: ApiService.clearoutPhoneUpdateTimezone,
    API_BODY: ['cop_user_id', 'timezone', 'client_secret', 'crm_user_data']
  },
  ADD_BONUS: {
    API: ApiService.giveBonusCopUser,
    API_BODY: ['client_secret', 'cop_user_id', 'created_by', 'credits', 'description', 'crm_user_data']
  },
  RESET_BONUS: {
    API: ApiService.resetBonusCopUser,
    API_BODY: ['client_secret', 'cop_user_id', 'created_by', 'description', 'crm_user_data']
  },
  ADDITIONAL_INFO: {
    API: ApiService.getClearoutPhoneUserProfileAdditionalInfo,
    API_BODY: ['client_secret', 'cop_user_id']
  },
  UPDATE_API_RATE_LIMIT: {
    API: ApiService.updateRateLimitForCOPIndividual,
    API_BODY: ['api_rate_limits', 'cop_user_id', 'reason', 'user_id', 'client_secret', 'crm_user']
  },
  ADD_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneAddDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'reason', 'client_secret', 'crm_user_data']
  },
  REMOVE_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneUsersRemoveDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'affiliate', 'client_secret', 'crm_user_data']
  },
  UPDATE_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneUpdateDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'client_secret', 'crm_user_data', 'reason']
  },
  RECEIPT_LIST: {
    API: ApiService.listCopInvoices,
    API_BODY: ['client_secret', 'cop_user_id']
  },
  GENERATE_RECEIPT: {
    API: ApiService.clearoutPhoneMarkAsPaid,
    API_BODY: ['cop_user_id', 'user_id', 'amount', 'payment_type', 'credits', 'payment_gateway', 'description', 'client_secret']
  },
  LOGOUT_SESSIONS: {
    API: ApiService.logoutCopAllSessionOfClearoutUser,
    API_BODY: ['cop_user_id', 'session_type', 'client_secret', 'crm_user_data']
  },
  BLOCK_USER: {
    API: ApiService.blockClearoutPhoneUser,
    API_BODY: ['cop_user_id', 'user_id', 'reason', 'client_secret', 'crm_user_data']
  },
  UNBLOCK_USER: {
    API: ApiService.unBlockClearoutPhoneUser,
    API_BODY: ['cop_user_id', 'user_id', 'reason', 'client_secret', 'crm_user_data']
  },
  DELETE_ACCOUNT: {
    API: ApiService.deleteClearoutPhoneUser,
    API_BODY: ['cop_user_id', 'reason', 'client_secret', 'crm_user_id']
  },
  FORGOT_PASSWORD_LINK: {
    API: ApiService.copResetTokens,
    API_BODY: ['user_id', 'action_type', 'client_secret', 'crm_user_id', 'crm_user_data']
  },
  SIGN_UP_LINK: {
    API: ApiService.copResetTokens,
    API_BODY: ['user_id', 'action_type', 'client_secret', 'crm_user_id', 'crm_user_data']
  },
  USER_LIST_DOWNLOAD: {
    API: ApiService.getClearoutPhoneUsersList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'download', 'client_secret']
  },
  GET_ABUSE_LIST_WRT_COUNTRY: { // GET_COUNTRIES
    API: ApiService.getCopAbuseListWrtCountry,
    API_BODY: ['country', 'client_secret']
  },
  ADD_ABUSE_ENTRY: {
    API: ApiService.addCopAbuseListEntry,
    API_BODY: ['type', 'value', 'reason', 'block_free_email', 'client_secret', 'crm_user_data', 'country_code']
  },
  SIGN_UP_ABUSE_LIMITTER: {
    API: ApiService.copSignUpAbuseLimiter,
    API_BODY: ['domain', 'ip', 'action', 'email', 'crm_user_data', 'client_secret']
  },
  GET_ABUSE_LIST: {
    API: ApiService.getCopAbuseList,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'abuse_id', 'client_secret']
  },
  COUPONS_LIST: {
    API: ApiService.listCopCoupons,
    API_BODY: ['skip', 'limit', 'search', 'filters', 'sort', 'client_secret']
  },
  CREATE_COUPONS: {
    API: ApiService.createCopCoupon,
    API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
      'discount_type', 'discount_min', 'discount_max', 'discount_value', 'client_secret', 'credits', 'daily_verify_limit']
  },
  UPDATE_COUPONS: {
    API: ApiService.editCopCoupon,
    API_BODY: ['name', 'coupon', 'expiry', 'status', 'user_id', 'description', 'affiliated_to', 'coupon_type', 'allowed_redeem_count',
      'discount_type', 'discount_min', 'discount_max', 'discount_value', 'client_secret', 'credits', 'daily_verify_limit']
  },
  UPDATE_ABUSE_ENTRY: {
    API: ApiService.updateCopAbuseListEntry,
    API_BODY: ['type', 'value', 'reason', 'block_free_email', 'abuse_id', 'exempted', 'client_secret', 'crm_user_data', 'country_code']
  },
  GET_ABUSE_LOGS: {
    API: ApiService.getClearoutPhoneAbuseActivityLogs,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  GET_ABUSE_UPDATE_ACTIVITY: {
    API: ApiService.getClearoutPhoneAbuseUpdateLogs,
    API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
  },
  REMOVE_API_RATE_LIMIT: {
    API: ApiService.removeRateLimitForCOPIndividual,
    API_BODY: ['api_names', 'cop_user_id', 'reason', 'user_id', 'client_secret', 'crm_user']
  },
  ALLOWED_DAILY_LIMIT: {
    API: ApiService.clearoutPhoneAddDailyLimit,
    API_BODY: ['cop_user_id', 'crm_user_id', 'daily_verify_limit', 'reason', 'client_secret', 'crm_user_data']
  },
  GET_COP_LIST: {
    API: ApiService.getCopLists,
    API_BODY: ['client_secret', 'skip', 'limit', 'search', 'filters', 'sort']
  },
  DOWNLOAD_COP_LIST_INPUT_FILE: {
    API: ApiService.downloadCopInputFile,
    API_BODY: ['list_id', 'client_secret']
  },
  DOWNLOAD_RESULT_FILE: {
    API: ApiService.downloadresultForPhoneClearoutList,
    API_BODY: ['co_user_id', 'list_id', 'options', 'user_id', 'client_secret']
  },
  GET_PRODUCT_LIST: {
    API: ApiService.getCopProductList,
    API_QUERY: ['client_secret','crm_user_data','cop_user_id', 'limit', 'skip', 'total_required', 'filters', 'attach_product']
  },
  GET_ADDON_PRODUCT_INFO: {
    API: ApiService.getCoAddonProductInfo,
    API_BODY: []
  },
  GET_PRODUCT: {
    API: ApiService.getCopProduct,
    API_QUERY: ['client_secret', 'crm_user_data', 'product_id', 'product_type', 'currency_code', 'credits', 'product_name', 'amount', 'is_custom', 'recurring', 'can_display', 'interval_count', 'interval_unit', 'interval_times', 'dropdown_options', 'credit_interval_count', 'credit_interval_unit', 'reset_credits', 'limit_interval_count', 'limit_interval_unit',]
  },
  CREATE_PRODUCT: {
    API: ApiService.createCopProduct,
    API_BODY: ['client_secret', 'crm_user_data', 'product_type', 'currency_code', 'credits', 'product_name', 'amount', 'is_custom', 'recurring', 'can_display', 'interval_count', 'interval_unit', 'interval_times', 'dropdown_options', 'credit_interval_count', 'credit_interval_unit', 'reset_credits', 'limit_interval_count', 'limit_interval_unit',]
  },
  UPDATE_USERS_PAYMENT_SETTINGS: {
    API: ApiService.udpateCopUserPaymentSettings,
    API_BODY: ['client_secret', 'crm_user_details', 'cop_user_id', 'acap', 'alap', 'reason']
  },
  ATTACH_PRODUCT: {
    API: ApiService.attachCopProduct,
    API_BODY: ['client_secret', 'crm_user_data', 'cop_user_id', 'product_id']
  },
  DETACH_PRODUCT: {
    API: ApiService.detachCopProduct,
    API_BODY: ['client_secret', 'crm_user_data', 'cop_user_id', 'product_id']
  },
  USER_PLANS_AND_ACTIVE_SUBSCRIPTIONS: {
    API: ApiService.getCopUsersPlansAndActiveSubscriptions,
    API_BODY: ['client_secret', 'crm_user_details', 'cop_user_id', 'filters']
  },
  GET_PRODUCT_DROP_DOWN_LIST: {
    API: ApiService.getCopProductDropDownList,
    API_QUERY: ['filters', 'client_secret']
  },
  UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT: {
    API: ApiService.udpateCopUserDefaultPaymentAccount,
    API_BODY: ['cop_user_id','client_secret', 'crm_user_data', 'stripe_account', 'paypal_account', 'reason']
  },
  RADAR: {
    API: ApiService.copToggleRadar,
    API_BODY: ['cop_user_id', 'client_secret', 'crm_user_data', 'reason', 'radarStatus']
  },
  GET_SYSTEM_RADAR_SETTINGS: {
    API: ApiService.getCopSystemRadarSettings,
    API_QUERY: ['key','client_secret', 'crm_user_details']
  }, 
  UDPATE_SYSTEM_RADAR_SETTINGS: {
    API: ApiService.updateCopSystemRadarSettings,
    API_BODY: ['settings', 'client_secret', 'crm_user_details']
  },
}
import React, { useContext, useState, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';

import * as Constants from '../../helpers/constants';
import * as FilterConstants from '../widgets/filters/constants'
import { ApiHelper } from '../../helpers/apihelper'
import { INITIAL_FILTER_STATE } from './helpers/constants';

import usePolling from '../hooks/polling';

import IndividualList from './components/individual_list';
import TablePagination from '../widgets/pagination';
import FiltersComponent from '../widgets/filters';
import LoadingWrapper from '../widgets/loading';

import AppConfigContext from '../app_layout/app_config_context'

import '../../static/css/service_list_page/service_lists.css'

const ServiceList = ({
  service
}) => {

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client
  const abortControllerRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const list_name = queryParams.get('name');
  const user = useSelector((state) => state.user);
  const initialState = {
    lists: [],
    selectedPaginationFilter: { display_name: '10', action_value: 10 },
    search: '',
    userEmail: '',
    filters: INITIAL_FILTER_STATE[service.NAME],
    orgId: null,
    currentPageIndex: 0,
    pageSize: 10,
    totalPages: 0,
    orgName: null,
    pollingActive: false,
    isLoading: false
  }

  const [state, setState] = useState(initialState);

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME

  const filterParams = useCallback(
    (filters) => {
      let user_email = state.userEmail ? state.userEmail.toLowerCase() : state.userEmail

      const queryFilter = { user_email }; // Initialize with common property

      const addFilter = (key, value) => {
        if (value !== null && value !== undefined && value !== '') {
          queryFilter[key] = value;
        }
      };

      if (service.NAME === 'email_verifier') {
        let dateFilter
        if (_.has(filters, 'date_range.value')) {
          dateFilter = {
            key: 'created_on',
            preset: filters.date_range.value,
          }
        }
        addFilter('date_filter', dateFilter);
        addFilter('verified', filters.verified?.value);
        if (_.get(filters, 'type.value') !== 'all') {
          addFilter('type', filters.type?.value);
        }
        addFilter('org_id', state.orgId)
      }
      else if (service.NAME === 'email_finder') {
        let dateFilter
        if (_.has(filters, 'date_range.value')) {
          dateFilter = {
            key: 'created_on',
            preset: filters.date_range.value,
          }
        }
        addFilter('date_filter', dateFilter);
        addFilter('processed', filters.processed?.value);
        addFilter('org_id', state.orgId)
      }
      else if (service.NAME === 'prospect') {
        let dateFilter = []
        if (_.has(filters, 'date_range.value')) {
          dateFilter.push({
            key: 'created_on',
            preset: filters.date_range.value,
          })
        }
        if (_.has(filters, 'enriched_on.value')) {
          dateFilter.push({
            key: 'enriched_on',
            preset: filters.enriched_on.value,
          });
        }
        addFilter('date_filter', dateFilter.length ? dateFilter : null);
        addFilter('enriched', filters.enriched?.value);
        addFilter('type', filters.type?.value);
        addFilter('org_id', state.orgId)
      }
      else if (service.NAME === 'phone_number_verifier') {
        let dateFilter
        if (_.has(filters, 'date_range.value')) {
          dateFilter = {
            key: 'created_on',
            preset: filters.date_range.value,
          }
        }
        addFilter('date_filter', dateFilter);
        if (_.get(filters, 'in_progress_status.value') === 'in_progress') {
          addFilter('in_progress_status', 'all');
        }
        else {
          addFilter('verified', filters.in_progress_status?.value);
        }

        if (_.get(filters, 'type.value') !== 'all') {
          addFilter('type', filters.type?.value);
        }
        addFilter('org_id', state.orgId)
      }

      return queryFilter;
    },
    [service.NAME, state.userEmail, state.orgId]
  );

  const getApiParams = useCallback(() => {
    let params = {
      skip: state.currentPageIndex * state.selectedPaginationFilter.action_value,
      limit: state.selectedPaginationFilter.action_value,
      search: state.search || list_name,
      filters: filterParams(state.filters),
      sort: {
        created_on: 'desc',
      },
      client_secret: Constants.CLIENT_SECRET,
    };
    return params;
  }, [
    state.currentPageIndex,
    state.selectedPaginationFilter.action_value,
    state.search,
    state.filters,
    filterParams,
    list_name
  ]);

  const checkPolling = useCallback((list) => {
    let isPollingActive = false;
    const hasRunningList = _.find(list, (obj) => {
      const actionInProgress = service.NAME === 'prospect'
        ? obj['enrich_action_inprogress']
        : obj['action_inprogress'];
      return actionInProgress?.status === 'running' || actionInProgress?.status === 'queued';
    });
    if (hasRunningList) {
      isPollingActive = true;
    } else {
      isPollingActive = false;
    }
    setState((prevState) => ({
      ...prevState,
      pollingActive: isPollingActive
    }));
  }, [service.NAME]);

  const getServiceLists = useCallback((polling) => {

    if (polling) {
      setState((prevState) => ({
        ...prevState,
        isLoading: false
      }));
    }
    else {
      setState((prevState) => ({
        ...prevState,
        isLoading: true
      }));
    }

    let apiParams = getApiParams();
    let body = _.pick(apiParams, ApiHelper(client, service.GET_LIST_API).API_BODY);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    ApiHelper(client, service.GET_LIST_API)
      .API(client, body, controller.signal)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            lists: res.data.list,
            totalPages: Math.ceil(res.data.count / prevState.pageSize),
            isLoading: false
          }));
          // Check if polling should be started or stopped based on the response
          checkPolling(res.data.list);
        }
        else {
          setState((prevState) => ({
            ...prevState,
            lists: [],
            isLoading: false
          }));
        }

      })
      .catch((err) => {
        console.log('Get Lists API error:', err);
        setState((prevState) => ({
          ...prevState,
          isLoading: false
        }));
      });
  }, [client, service.GET_LIST_API, abortControllerRef, getApiParams, checkPolling]);

  useEffect(() => {
    const initialFilters = { ...INITIAL_FILTER_STATE[service.NAME] };
    setState((prevState) => ({
      ...prevState,
      filters: initialFilters,
      userEmail: '',
      search: ''
    }));
  }, [service.NAME, client])

  useEffect(() => {
    let urlParams = new URLSearchParams(location.search);
    let newState = {};

    if (urlParams.has('user_email')) {
      newState.userEmail = urlParams.get('user_email');
    }
    if (urlParams.has('org_id')) {
      newState.orgId = urlParams.get('org_id');
    }
    if (urlParams.has('org_name')) {
      newState.orgName = urlParams.get('org_name');
    }

    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }, [location.search]);

  usePolling(
    () => {
      getServiceLists(true); // Callback function for polling
    },
    state.pollingActive ? Constants.POLLING_INTERVAL : null // Delay in milliseconds or null to stop polling
  );

  useEffect(() => {
    getServiceLists()
  }, [getServiceLists])

  const handlePageChange = (newPage) => {
    setState((prevState) => ({ ...prevState, currentPageIndex: newPage }));
  };

  const handlePageSizeChange = (event) => {
    const newPageSize = Number(event.target.value);

    setState((prevState) => ({
      ...prevState,
      selectedPaginationFilter: { display_name: newPageSize.toString(), action_value: newPageSize },
      pageSize: newPageSize
    }));
  };

  const onSetFilters = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }))
  }

  const refreshPage = () => {
    getServiceLists()
  }

  const onSearch = (event) => {
    setState((prevState) => ({
      ...prevState,
      userEmail: event.target.value
    }));
  }

  const onListNameSearch = (event) => {
    setState((prevState) => ({
      ...prevState,
      search: event.target.value
    }));
  }

  const clearEmailAddressSearch = () => {
    setState((prevState) => ({
      ...prevState,
      userEmail: ''
    }));
  }

  const clearListNameSearch = () => {
    setState((prevState) => ({
      ...prevState,
      search: ''
    }));
  }

  return (
    <div
      className='d-flex flex-column'
      style={{ minHeight: '600px' }}
    >
      {state.isLoading ? (
        <div className="loader-container">
          <LoadingWrapper
            type="bars"
            color="#6229e3"
            height={50}
            width={50}
          />
        </div>
      ) : null}
      <h1 className='mb-2 header'>
        {clientDisplayName} {service.DISPLAY_NAME} Lists {state.orgName ? '/' + state.orgName : ''}
      </h1>
      <FiltersComponent
        filtersToInclude={FilterConstants.FILTERS_TO_INCLUDE[client][service.VALUE]}
        filters={FilterConstants.SERVICE_LIST_PAGE[service.NAME].filters}
        onSetFilters={onSetFilters}
        filterState={state.filters}
        skipFilter={['org_filter']}
        resetFilters={FilterConstants.SERVICE_LIST_PAGE[service.NAME].onResetFilters}
        service={service}
        refreshPage={refreshPage}
        searchPlacholder={'Filter by Email address'}
        searchValue={state.userEmail}
        onSearch={onSearch}
        listName={state.search}
        onListNameSearch={onListNameSearch}
        clearSearch={clearEmailAddressSearch}
        clearListNameSearch={clearListNameSearch}
      />
      <ul className="my-lists">
        {state.lists.length > 0 ? (
          state.lists.map((individualList) => (
            <IndividualList
              key={Math.floor(Math.random() * 90000)}
              individualList={individualList}
              user={user}
              list_id={individualList.list_id}
              client={client}
              service={service}
            />
          ))
        ) : (
          <div className="list-container text-center p-4 bg-white">
            <p className="m-0">No Lists Added</p>
          </div>
        )}
      </ul>
      {
        state.lists.length > 0 ? (
          <div className="d-flex justify-content-between pt-3 pb-1">
            <div style={{ paddingLeft: '0.5rem' }}>
              <Form.Select
                value={state.pageSize}
                onChange={handlePageSizeChange}
                className="form-select"
              >
                {Constants.PAGE_SIZE_OPTIONS.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Form.Select>
            </div>
            <div>
              <TablePagination
                currentPageIndex={state.currentPageIndex}
                totalpage={state.totalPages}
                updateTableData={handlePageChange}
              />
            </div>
          </div>
        ) : null
      }
    </div>
  )
}

export default ServiceList
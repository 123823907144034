import React, { useCallback, useEffect, useState, useContext } from 'react'
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, Button, Tab, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import Breadcrumbs from '../../widgets/breadcrumb';
import * as Constants from '../../helpers/constants';
import ToggleRadarModel from './toggle_radar';
import TabsInfo from './tabs';
import * as Helpers from '../../helpers/helper'
import showToastNotification from '../widgets/toastify';
import * as IndividualUserConstants from './helpers/constant'
import * as UserInfoFormatters from './helpers/user_info_formatters'
import ActionButtons from './action_buttons';
import QuickLinks from './quick_links'
import { ApiHelper } from '../../helpers/apihelper';
import AppConfigContext from '../app_layout/app_config_context'
import ForgotPasswordLink from './action_buttons/components/password_link';
import ClickToCopy from '../widgets/copy_onclick';
import ApiService from '../../helpers/apiservice'
import CustomOverlayTrigger from '../widgets/overlay_trigger';

import './../../static/css/loader.css'
import './../../static/css/profile.css'
import 'react-toggle/style.css'

const initialState = {
  userData: null,
  isRadarModalOpen: false,
  isChecked: true,
  additionalUserData: {},
  isLoading: false,
  imageUrl: '',
  userSubscriptions: [],
  copSubscriptions: []
}

const ClearoutIndividualUserInfo = ({ userId }) => {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const userName = useSelector((state) => state.user.name);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('user_id') || userId;

  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client

  const getClearoutUserInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, ApiHelper(client, 'USER_INFO').API_BODY)

    ApiHelper(client, 'USER_INFO').API(client, body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({ ...prevState, userData: response.data, imageUrl: response.data.profile_picture }))
          {
            if (response.data.radar && response.data.radar.active !== undefined) {
              setState(prevState => ({ ...prevState, isChecked: response.data.radar.active }))
            }
          }
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [user_id, client])

  const getClearoutUserAdditionalInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      cop_user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, ApiHelper(client, 'ADDITIONAL_INFO').API_BODY)

    ApiHelper(client, 'ADDITIONAL_INFO').API(client, body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({ ...prevState, additionalUserData: response.data }))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [user_id, client])

  const getUserInfo = useCallback(() => {
    getClearoutUserInfo()
    getClearoutUserAdditionalInfo()
  }, [getClearoutUserInfo, getClearoutUserAdditionalInfo])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])


  const getUsersSubscriptions = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
    }
    let body = _.pick(requestBody, ApiHelper(client, 'USER_SUBSCRIPTIONS').API_BODY)
    ApiHelper(client, 'USER_SUBSCRIPTIONS').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({
            ...prevState,
            userSubscriptions: response.data[0]
          }))
        } else {
          let message = response.message || 'Something went wrong'
          showToastNotification({
            message,
            isSuccess: false,
          });
        }
      })
      .catch((err) => {
        let message = err.message || 'Something went wrong'
        showToastNotification({
          message,
          isSuccess: false,
        });
      })
  }, [user_id, client])

  //cop subs 
  const getCopUsersSubscriptions = useCallback(() => {
    if (client !== 'clearoutphone') return;
    let body = {
      cop_user_id: user_id,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: JSON.stringify({
        user_id: id,
        name: userName,
      }),
    }
    ApiService.clearoutPhoneUserSubsList(body)
      .then((response) => {
        setState(prevState => ({
          ...prevState,
          copSubscriptions: response.data
        }))
      }
      )
      .catch((err) => {
        console.log(err)
      })
  }, [user_id, client, id, userName])

  // mount

  useEffect(() => {
    if (client !== 'clearoutphone') {
      getUsersSubscriptions()
    }
  }, [getUsersSubscriptions, client])

  useEffect(() => {
    if (client === 'clearoutphone')
      getCopUsersSubscriptions()
  }, [getCopUsersSubscriptions, client])

  const handletoggleModal = () => {
    setState(prevState => ({ ...prevState, isRadarModalOpen: false }))
  };

  const handleToggleChange = () => {
    let requestBody = {
      co_user_id: user_id,
      cop_user_id: user_id,
      user_id: id,
      reason: state.reason,
      radarStatus: true,
      crm_user_data: JSON.stringify({
        user_id: id,
        name: userName,
      }),
      client_secret: Constants.CLIENT_SECRET
    };

    let body = _.pick(requestBody, ApiHelper(client, 'RADAR').API_BODY)
    // Toggle the isChecked state
    setState(prevState => ({ ...prevState, isChecked: !state.isChecked }))

    // If the toggle is deactivated, open the modal
    if (state.isChecked) {
      setState(prevState => ({ ...prevState, isRadarModalOpen: true }));
    } else {
      handleToggleRadarApiCall(body);
    }
  };

  const handleToggleRadarApiCall = (body) => {
    ApiHelper(client, 'RADAR').API(client, body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'failed',
            isSuccess: false,
          });
        } else (response.status === Constants.CO_API.SUCCESS); {
          showToastNotification({
            message: 'Radar settings updated successfully',
            isSuccess: true,
          });
          handletoggleModal();
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  //formatting 
  const getLeftTable = () => {
    const leftTable = IndividualUserConstants.BASIC_USER_INFO[client].OVERVIEW_SECTION

    return (
      <>
        {leftTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='py-1'
            >
              <CustomOverlayTrigger
                placement={'top'}
                content={opts.label}
              >
                <p className="p-0 m-0 fw-bold text-muted truncate-text">
                  {opts.label}
                </p>

              </CustomOverlayTrigger>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                subscriptionData: state.userSubscriptions,
                copSubscriptions: state.copSubscriptions,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getRightTable = () => {
    const rightTable = IndividualUserConstants.BASIC_USER_INFO[client].RIGHT_TABLE

    return (
      <>
        {rightTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='pb-3'
            >
              <p className='mb-1 text-muted fw-bold'>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }

  const quickActionAddCreditsAndRadar = () => {
    const quickActions = IndividualUserConstants.BASIC_USER_INFO[client].QUICK_ACTIONS

    const filteredQuickActions = quickActions.filter((action) => {
      if (action.label === 'Credits' && state.userData.user_type === 'organization') {
        return false;
      }
      return true;
    });

    return (
      <>
        {filteredQuickActions.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='py-2 d-flex justify-content-between'
            >
              <p className='mb-1 text-muted fw-bold'>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                isChecked: state.isChecked,
                handleToggleChange: handleToggleChange
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getBadges = () => {
    let tagsToShow = [];

    // Handle both string and object types in the tags array
    state.userData.tags.forEach((tag) => {
      if (typeof tag === 'string') {
        tagsToShow.push(tag);
      } else if (typeof tag === 'object' && tag.type) {
        tagsToShow.push(tag.type);
      }
    });

    let userRole = state.userData.user_role;
    if (userRole === 'analyst') userRole = 'executive';

    tagsToShow.push(userRole);

    const tagBackgrounds = {
      executive: 'primary',
      owner: 'warning',
      paid: 'success',
      admin: 'dark',
      default: 'secondary',
    };

    return (
      <div className='d-flex gap-1'>
        {tagsToShow.map((tag) => {
          const bgColor = tagBackgrounds[tag] || tagBackgrounds.default;
          return (
            <Badge
              key={tag}
              bg={bgColor}
            >
              {Helpers.capitalizeFirstLetter(tag)}
            </Badge>
          );
        })}
      </div>
    );
  };

  if (!state.userData) {
    return <p>No Data</p>
  }

  const orgIdFormatter = () => {
    return (
      <div className='d-flex py-1 flex-wrap'>
        <div className='text-muted'>
          {state.userData.org_id ? (
            <ClickToCopy text={state.userData.org_id} />
          ) : '-'}
        </div>
        <div className='px-1'>
          {state.userData.user_type === 'organization' ? (
            <>
              <Link
                to={`/${client}/organization_info?org_id=${state.userData.org_id}`}
                title='org info'
                className='ms-1'
                target='blank'
              >
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className='text-primary'
                />
              </Link>
            </>
          ) : null}
        </div>
      </div>
    )
  }

  const getTextColor = (data) => {
    if (data.account_status === 'removed') {
      return '#ff6767'
    } else if (!data.activated_on || data.activated_on === 'Yet to Activate') {
      return '#a3a3a3'
    } else if (data.status === 'active') {
      return '#000000'
    } else {
      return '#e48725'
    }
  }

  const formatLabel = (text) =>
    text
      .split('_')
      .map(word => _.startCase(word))
      .join(' ');

  const {name, email} = state.userData;
  const {job_title, industry, organization_name} = state.additionalUserData.user_profile_additional_info || {};

  return (
    <>
      <Breadcrumbs
        options={[
          {
            label: `${formatLabel(client)} Users`,
            href: `/${client}/users`,
            active: false
          },
          {
            label: state.userData.name
              ? formatLabel(state.userData.name)
              : state.userData.email,
            href: '/clearout/individual_User_Info',
            active: true
          }
        ]}
      />
      <Container fluid>
        <Row>
          {/* Left section */}
          <Col
            md={3}
            className='d-flex flex-column'
          >
            <Card
              className='p-0 h-100 custom-card mb-2'
            >
              <div className='d-flex flex column gap-1'>
                <div >
                  <img
                    src={state.imageUrl || 'https://wallpapers.com/images/hd/placeholder-profile-icon-20tehfawxt5eihco.jpg'}
                    alt='Avatar'
                    className='rounded-circle image-card'
                  />
                </div>
                <div
                  className='d-flex flex-column align-items-start mt-2'
                >
                  <h5
                    style={{
                      color: getTextColor(state.userData),
                      textAlign: 'start',
                    }}
                  >
                    {name || email}
                  </h5>
                  <p className='text-muted mb-0'>{Helpers.capitalizeFirstLetter(job_title) || '-'}</p>
                  <p className='text-muted mb-0'>{Helpers.capitalizeFirstLetter(industry) || '-'}</p>
                  <p className='text-muted mb-0'>{Helpers.capitalizeFirstLetter(organization_name) || '-'}</p>
                </div>
              </div>
              <div className='d-flex gap-4 px-2'>
                <a
                  href={`mailto:${state.userData.email}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FontAwesomeIcon icon='fa-solid fa-envelope' />
                </a>
                <a
                  href={`tel:${state.userData.phone_number}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <FontAwesomeIcon icon='fa-solid fa-phone' />
                </a>
                {state.additionalUserData?.user_profile_additional_info?.linkedin_url && (
                  <a
                    href={`${state.additionalUserData.user_profile_additional_info.linkedin_url}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <FontAwesomeIcon icon='fa-brands fa-linkedin' />
                  </a>
                )}
              </div>
              <div>
                <Tab.Container defaultActiveKey='account'>
                  <Nav
                    variant='tabs'
                    className='d-flex justify-content-center py-3'
                  >
                    <Nav.Item>
                      <Nav.Link eventKey='account'>
                        <FontAwesomeIcon
                          icon={state.userData.user_type === 'organization' ? 'fa-solid fa-users' : 'fa-solid fa-user'}
                        />
                        <span className='ps-2'>Account</span>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content className='p-3'>
                    <Tab.Pane eventKey='account'>
                      <div className='d-flex justify-content-between'>
                        <p className='fw-bold text-muted'>Tags</p>
                        <Button
                          variant='outline-primary'
                          size='sm'
                          className='sm-btns'
                        >
                          <FontAwesomeIcon icon='fa-solid fa-circle-plus' />
                        </Button>
                      </div>
                      <div className='d-flex flex-wrap gap-2'>
                        {getBadges()}
                      </div>
                      <div className='py-3'>
                        <p className='fw-bold text-muted m-0 p-0'>{state.userData.user_type === 'organization' ? 'Org Id' : 'User Id'}</p>
                        {orgIdFormatter()}
                      </div>
                      {state.userData.user_type === 'organization' && state.userData.user_role === 'admin' || state.userData.user_role === 'analyst' ? (
                        <div className='pb-3'>
                          <p className='fw-bold text-muted m-0 p-0'>User Id</p>
                          <p className='p-0 m-0 text-muted'>
                            {state.userData.user_id ? (
                              <ClickToCopy text={state.userData.user_id} />
                            ) : '-'}
                          </p>
                        </div>
                      ) : null}
                      <div>
                        <p className='text-muted m-0'>
                          {getRightTable()}
                        </p>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </Card>
          </Col>
          {/* Right section */}
          <Col
            md={9}
            className='d-flex flex-column'
          >
            <Card className='w-100 custom-card p-1'>
              <h5 className='text-muted pb-2 ps-2'>Overview</h5>
              <div className='container'>
                <div className='row'>
                  {/* Left Section */}
                  <div className='col-lg-9'>
                    <div className='row gy-3'>
                      {getLeftTable().props.children.map((item, idx) => (
                        <div
                          className='col-12 col-sm-6 col-md-6 col-lg-4'
                          key={idx}
                        >
                          <div className='p-2 border rounded bg-white h-100'>
                            {item}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* Right Section */}
                  <div className='col-lg-3 bg-gray'>
                    <div className='p-2 border h-100'>
                      <p className='fw-bold text-center'>Quick Actions</p>
                      {state.userData.user_type === 'individual' ? (
                        <div className='m-0'>
                          {quickActionAddCreditsAndRadar()}
                        </div>
                      ) : null}
                      {state.userData.user_type === 'organization' && state.userData.user_role === 'owner' ? (
                        <div className='m-0'>
                          {quickActionAddCreditsAndRadar()}
                        </div>
                      ) : null}
                      <ForgotPasswordLink
                        client={client}
                        userData={state.userData}
                      />
                      <ActionButtons
                        client={client}
                        userData={state.userData}
                        getUserInfo={getUserInfo}
                      />
                      <div className='d-flex justify-content-around gap-2 pt-3'>
                        <QuickLinks
                          client={client}
                          userId={userId}
                          userData={state.userData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
            {/* Tabs */}
            <Card className='mt-3 flex-grow-1 w-100 custom-card'>
              <TabsInfo
                userData={state.userData}
                getUserInfo={getUserInfo}
                client={client}
                userId={user_id}
              />
            </Card>
          </Col>
        </Row>
        <ToggleRadarModel
          show={state.isRadarModalOpen}
          onHide={handletoggleModal}
          co_user_id={user_id}
          name={state.userData.name}
          handleToggleRadar={handleToggleRadarApiCall}
          getUserInfo={getUserInfo}
          client={client}
        />
      </Container >
    </>
  )
}
export default ClearoutIndividualUserInfo

import React, {useRef,useState, useCallback, useContext} from 'react';
import AsyncSelect from 'react-select/async'; 
import _ from 'lodash';

import * as ConstantsRenewal from './../../helpers/constant'
import * as Constants from './../../../../helpers/constants';
import { ApiHelper } from '../../../../helpers/apihelper';
import AppConfigContext from '../../../app_layout/app_config_context';

const CouponCodeDropdown = ({ onStateChange, coupon_code, selected_product, billing_type }) => {
  
  const appConfig = useContext(AppConfigContext)
  const client = appConfig.client
  const abortControllerRef = useRef(null);

  const [couponOptions, setCouponOptions] = useState([]);

  const onCouponChange = (selectedOption) => {
    onStateChange('coupon_code', selectedOption ? selectedOption.value : ''); // Update coupon_code
  };

  const fetchCoupons = useCallback((value) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    const requestBody = {
      skip: 0,
      search: value,
      limit: 20,
      filters: {
        coupon_type:
        billing_type === Constants.BILLING_TYPE.annual.value
          ? Constants.COUPON_TYPE.bonus.value
          : Constants.COUPON_TYPE.discount.value,
      },
      client_secret: Constants.CLIENT_SECRET,
    };

    const body = _.pick(requestBody, ApiHelper(client, 'COUPONS_LIST').API_BODY);
    return ApiHelper(client, 'COUPONS_LIST')
      .API(client, body, controller.signal)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          const coupons = response.data.data
            .sort((a, b) => new Date(b.created_on) - new Date(a.created_on))
            .map((coupon) => ({
              label: coupon.name,
              value: coupon.coupon_id,
            }));

          setCouponOptions(coupons);
          return coupons;
        }
        return []
      })
      .catch((error) => {
        console.error(error);
      });
  }, [client, billing_type]);

  const showCoupon = (() => {

    if (selected_product && selected_product.display_name == Constants.PRODUCT_DISPLAY_NAME.pay_as_you_go_credits) return true;

    if (!selected_product || !selected_product.renewal) return false;

    const renewal = selected_product.renewal;
    const patterns = ConstantsRenewal.RENEWAL_UNITS.year.patterns;

    return patterns.some(
      (pattern) => pattern.unit === renewal.unit && pattern.count === renewal.count
    )
  })();

  return (
    <>
      {showCoupon && (
        <div className="product-type-dropdown">
          <label>Select Coupon</label>
          <AsyncSelect
            loadOptions={fetchCoupons}
            defaultOptions={true}
            placeholder='Select a coupon'
            value={couponOptions.find((option => option.value === coupon_code) || null)}
            onChange={onCouponChange}
            cacheOptions={false}
            isSearchable
            isClearable
            className='product-custom-select'
          />

        </div>
      )}
    </>
  )
};


export default CouponCodeDropdown;
